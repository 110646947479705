<template>
  <div class="favorites-wrapper">
    <div class="content-wrapper">
      <p class="favorites-title">共收藏<span>{{ total > 0 ? total : '-' }}个</span>{{ title }}</p>
      <scroll-list
        :total="total"
        :data="data"
        :getData="getData"
        :immediateCheck="false">
        <van-swipe-cell v-for="item in data" :key="item.poiId" left-width="84px">
          <template slot="right">
            <van-button @click="handleFavorite(item)" square text="取消收藏" color="#E82447" class="delete-button"/>
          </template>
          <navigate-cell
            class="cell"
            @navigate="handleNavigate(item)"
            :showFavorite="false"
            :title="item.name"
            :label="item.poiAddr"
            :distance="item.distance"
            :isFavorite="item.isFavorite">
            <div class="desc-info" v-if="title === '自行车场'" slot="desc">
              <span>可借<i class="color-red ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
              <span>可还 <i class="color-green ml5"> {{ Math.ceil(Math.random() * 20) }}</i></span>
            </div>
          </navigate-cell>
        </van-swipe-cell>
      </scroll-list>
    </div>
  </div>
</template>

<script>
import wx from 'wx'
import getWxSignature from '@/api/getWxSignature'
import API from '@/api/index'
import { saveLoading } from '@/utils/utils'
const { addPoiCollect, deletePoiCollect, getPoiCollects } = API

export default {
  name: 'favorites',
  data () {
    return {
      data: [], // 分页数据
      total: -1,
      currentLocation: []
    }
  },
  computed: {
    // 收藏夹类型
    favoriteType () {
      let type = this.$route.params.type
      if (type === 'wc') {
        return 1
      } else if (type === 'car') {
        return 2
      } else if (type === 'cycle') {
        return 3
      } else {
        return '4,5,6,7,8,9'
      }
    },
    title () {
      let str = ''
      switch (this.favoriteType) {
        case 1:
          str = '公共厕所'
          break
        case 2:
          str = '停车场'
          break
        case 3:
          str = '自行车场'
          break
        default :
          str = ''
      }
      return str
    }
  },
  created () {
    this.$watch('currentLocation', (val) => {
      if (this.data.length) {
        this.data = this.formatData(this.data)
      }
    }, { immediate: true })
    getWxSignature(['openLocation'], () => {
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: ({ latitude, longitude }) => {
          this.currentLocation = [longitude, latitude]
        }
      })
    })
  },
  activated () {
    this.getData(true)
  },
  methods: {
    // 获取收藏夹列表
    async getData (isRefresh) {
      let res = await getPoiCollects({
        types: this.favoriteType
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.data.length
          this.data = this.formatData(res.data)
        } else {
          throw new Error(res.message)
        }
      }
    },
    // 格式化数据
    formatData (data) {
      const valid = Array.isArray(this.currentLocation) && this.currentLocation.length === 2 && window.AMap
      return data.map(item => {
        if (valid) {
          item.distance = window.AMap.GeometryUtil.distance([item.longitude, item.latitude], this.currentLocation)
        }
        item.isFavorite = true
        return item
      })
    },
    // 添加或取消收藏
    handleFavorite (pointData) {
      let data = {}
      let api = ''
      let successMsg = ''

      if (pointData.isFavorite) {
        // 取消收藏
        data = {
          poiId: pointData.poiId,
          type: pointData.type
        }
        api = deletePoiCollect
        successMsg = '取消收藏成功'
      } else {
        // 收藏
        data = {
          poiId: pointData.poiId,
          type: pointData.type,
          latitude: pointData.latitude,
          longitude: pointData.longitude,
          name: pointData.name,
          poiAddr: pointData.address
        }
        api = addPoiCollect
        successMsg = '收藏成功'
      }

      saveLoading({
        data,
        api,
        successMsg,
        success: () => {
          // this.$set(pointData, 'isFavorite', !pointData.isFavorite)
          const idx = this.data.findIndex(item => item.poiId === pointData.poiId)
          this.data.splice(idx, 1)
          this.total = this.data.length
        }
      })
    },
    // 导航
    handleNavigate ({ latitude, longitude, name, poiAddr }) {
      wx.openLocation({
        latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude, // 经度，浮点数，范围为180 ~ -180。
        name, // 位置名
        address: poiAddr // 地址详情说明
      })
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue'),
    'navigate-cell': () => import('@/components/NavigateCell.vue')
  }
}
</script>

<style lang="scss" scoped>
  .favorites-wrapper{
    background: #F1F2F5;
    .favorites-title{
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      height: 76px;
      line-height: 76px;
      span{
        color: #E82447;
      }
    }
    .delete-button {
      height: 100%;
    }
    .cell{
      margin-bottom: 20px;
      box-sizing: border-box;
      p{
        font-size: 24px;
        color: #9AA8B4;
        margin-top: 9px;
        span:last-child{
          margin-left: 20px;
        }
      }
      .desc-info{
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 34px;
        .color-red{
          color: #E82447;
        }
        .color-green{
          color: #148DFF;
        }
      }
    }
  }
</style>
